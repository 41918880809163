import gql from 'graphql-tag';

import { CatchFragments } from './CatchType';
import { FishingWaterFragments } from './FishingWaterType';
import { ImageFragments } from './ImageType';
import { ProductFragments } from './ProductType';
import { ProductUnitFragments } from './ProductUnitType';
import { SpeciesFragments } from './SpeciesTypes';
import { UserFragments } from './UserType';
import { VideoFragments } from './VideoType';

export const PostFragments = {
  id: gql`
    fragment PostId on Post {
      _id: externalId
    }
  `,
  metadata: gql`
    fragment PostMetadata on Post {
      _id: externalId
      legacyId: id
      createdAt
      publishedAt
    }
  `,
  displayProductUnits: gql`
    fragment DisplayProductUnits on Post {
      displayProductUnits(first: 20) {
        totalCount
        edges {
          node {
            product {
              ...ProductBrandName
              ...ProductId
              externalId
              displayName
              reviews {
                averageRating: totalAverage(attribute: RATING)
                totalCount
              }
              image(croppingStrategy: NO_CROP, height: 280, width: 400) {
                id
                ...ImageFields
              }
            }
          }
        }
      }
    }
    ${ImageFragments.imageFields}
    ${ProductFragments.id}
    ${ProductFragments.brandName}
  `,
  productUnits: gql`
    fragment PostProductUnits on Post {
      productUnits(first: 20) {
        edges {
          node {
            ...ProductUnitId
            model
            image {
              ...ImageFields
            }
            product {
              ...ProductId
              ...ProductBrandName
              displayName
            }
          }
        }
      }
    }
    ${ImageFragments.imageFields}
    ${ProductFragments.brandName}
    ${ProductFragments.id}
    ${ProductUnitFragments.id}
  `,
  displayEntities: gql`
    fragment PostDisplayEntities on Post {
      displayEntity {
        __typename
        displayName
        _id: externalId
        legacyId: id
        displayIcon(height: 64, width: 64) {
          ...ImageFields
        }
      }
    }
    ${ImageFragments.imageFields}
  `,
  firstImageSquared384: gql`
    fragment PostfirstImageSquared384 on Post {
      images(first: 1, croppingStrategy: SMART, height: 384, width: 384) {
        edges {
          node {
            id
            ...ImageFields
          }
        }
      }
    }
    ${ImageFragments.imageFields}
  `,
  fishingWaterName: gql`
    fragment PostFishingWaterName on Post {
      fishingWater {
        ...FishingWaterId
        displayName
      }
    }
    ${FishingWaterFragments.id}
  `,
  videoFields: gql`
    fragment PostVideoFields on Post {
      video {
        ...VideoId
        hlsUrl
        webUrl
        originalUrl
        screenshot(width: 600) {
          ...ImageFields
        }
      }
    }
    ${ImageFragments.imageFields}
    ${VideoFragments.id}
  `,
};

// This is the minimal set off Post fields needed to create a PostCard component. It should be
// combined with an images field and can also be combined with product units.
export const PostCardFragment = gql`
  fragment PostCardFields on Post {
    ...PostMetadata
    ...PostDisplayEntities
    ...PostFishingWaterName
    ...PostVideoFields
    comments(last: 20) {
      totalCount
    }
    sharingPost
    likedByCurrentUser
    likers {
      totalCount
    }
    likesCount
    user {
      ...UserId
      ...UserAvatar
      isPremium
      firstName
      lastName
      createdAt
    }
    trip {
      id
      createdAt
      catches {
        totalCount
      }
      startedAt
      endedAt
    }
    catch {
      ...CatchMetadata
      ...CatchFishingMethodName
      ...CatchFishingWaterName
      ...CatchProductUnits
      isPersonalBest
      weight
      length
      catchAndRelease
      caughtAtLocalTimeZone
      caughtAtGmt
      hasExactPosition
      locationPrivacy
      species {
        ...SpeciesId
        displayName
        legacyId: id
      }
      trip {
        id
        catches {
          totalCount
        }
      }
    }
    text {
      text
    }
  }
  ${CatchFragments.metadata}
  ${CatchFragments.fishingWaterName}
  ${CatchFragments.fishingMethodName}
  ${CatchFragments.productUnits}
  ${PostFragments.displayEntities}
  ${PostFragments.fishingWaterName}
  ${PostFragments.metadata}
  ${PostFragments.videoFields}
  ${SpeciesFragments.id}
  ${UserFragments.id}
  ${UserFragments.avatar}
`;
