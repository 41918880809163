import { useEffect } from 'react';

import { usePathname, useSearchParams } from 'next/navigation';

import { pageEvent } from '../../helpers/analytics';
import { useClientHasRendered } from '../../helpers/hooks';
import { useReactAxe } from '../../helpers/hooks/useReactAxe';
import { IPageMetadata } from '../../interfaces/nextjs';

export const PageViewHandler = ({
  _page,
  _pageTitle,
  children,
}: { children: any } & IPageMetadata): JSX.Element => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const clientHasRendered = useClientHasRendered();

  useEffect(() => {
    if (clientHasRendered && pathname && searchParams) {
      pageEvent(pathname, searchParams, _pageTitle);
    }
  }, [_page, _pageTitle, clientHasRendered, pathname, searchParams]);

  useReactAxe(clientHasRendered);

  return children;
};
