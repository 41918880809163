/* eslint-disable react/no-multi-comp */
import React from 'react';

import { ConsentStatus } from '../../helpers/hooks/useConsent';
import { Button } from '../Clickables/Buttons/Button';
import { ExternalLink } from '../Clickables/ExternalLink/ExternalLink';
import { Modal } from '../Modal/Modal';
import { ModalBody } from '../Modal/ModalBody';
import { ModalFooter } from '../Modal/ModalFooter';
import { ModalHeader } from '../Modal/ModalHeader';
import { Text } from '../Text/Text';

export interface IConsentModalProps {
  onAccepted: () => void;
  consentStatus: ConsentStatus;
}

const Links = (): JSX.Element => (
  <>
    <ExternalLink href="/policies/terms-of-service/latest" target="_blank">
      Terms &amp; Conditions
    </ExternalLink>{' '}
    and{' '}
    <ExternalLink href="/policies/privacy/latest" target="_blank">
      Privacy Policy
    </ExternalLink>{' '}
    to continue using Fishbrain.
  </>
);

const UpdateCopy = (): JSX.Element => (
  <Text className="m-0">
    We&apos;ve made some changes to make sure your information is stored securely. Please agree to
    our new <Links />
  </Text>
);

const NewUserCopy = (): JSX.Element => (
  <Text className="m-0">
    Please agree to our <Links />
  </Text>
);

export const ConsentModal = ({ onAccepted, consentStatus }: IConsentModalProps): JSX.Element => (
  <Modal outerClassName="z-modal-consent" isOpen disableClose>
    <ModalHeader
      title={
        consentStatus === ConsentStatus.UpdateRequired
          ? 'Updated Terms and Conditions'
          : 'Fishbrain Terms and Conditions'
      }
    />
    <ModalBody>
      {consentStatus === ConsentStatus.UpdateRequired ? <UpdateCopy /> : <NewUserCopy />}
    </ModalBody>
    <ModalFooter>
      <div className="flex justify-end">
        <Button data-testid="ConsentAccept" onClick={onAccepted} className="ml-auto">
          I Agree
        </Button>
      </div>
    </ModalFooter>
  </Modal>
);
