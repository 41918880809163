import { twMerge } from 'tailwind-merge';

import styles from './LoadingSpinner.module.css';

type LoadingSpinnerProps = {
  loadingText?: string;
  isMini?: boolean;
  className?: string;
  innerClassName?: string;
  borderClassName?: string;
};

const LOADING_SPINNER_ITTERATOR = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];

export const LoadingSpinner = ({
  loadingText = '',
  isMini = false,
  className = '',
  innerClassName = '',
  borderClassName,
}: LoadingSpinnerProps): JSX.Element => (
  <div
    className={twMerge(`${isMini ? styles.mainMini : `${styles.main} m-16`}`, className)}
    data-testid="LoadingSpinner"
  >
    <div className={twMerge(`${isMini ? '' : 'p-6'}`, innerClassName)}>
      <div className={`${isMini ? styles.ldsringMini : styles.ldsring}`}>
        {LOADING_SPINNER_ITTERATOR.map(({ id }) => (
          <div
            key={id}
            className={twMerge(
              'border-t-bg-trench border-b-transparent border-l-transparent border-r-transparent',
              borderClassName,
            )}
          />
        ))}
      </div>
    </div>
    {loadingText && <span className="w-52 text-center text-lg text-trench">{loadingText}</span>}
  </div>
);
