import React from 'react';

import { twMerge } from 'tailwind-merge';

import { Heading } from '../Heading/Heading';

interface IProps {
  className?: string;
  title?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const ModalHeader = ({ className = '', title, ...rest }: IProps): JSX.Element =>
  title ? (
    <div className={twMerge('rounded-t-md border-b p-6', className)} {...rest}>
      <Heading className="mb-0" variant="sm">
        {title}
      </Heading>
    </div>
  ) : (
    <div className={twMerge('rounded-t-md border-b p-6', className)} {...rest} />
  );
