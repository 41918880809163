import { useState } from 'react';

import { useRutilusQuery } from '../../api/graphql/useRutilusQuery';
import {
  CURRENT_CONSENT_MARKETING_EMAIL_UUID,
  CURRENT_CONSENT_PRIVACY_UUID,
  CURRENT_CONSENT_TERMS_UUID,
} from '../../constants/constants';
import { GetConsents } from '../../interfaces/graphql';
import { getConsents } from '../../queries/rutilus/Me';
import { reportMykissError } from '../errorHandling';
import { getNodesFromConnection } from '../graphql';
import { isNewUser } from '../user/isNewUser';

import { useGiveConsent } from './useGiveConsent';
import { useUserInfo } from './useUserInfo';

export enum ConsentStatus {
  Accepted = 'ACCEPTED',
  UpdateRequired = 'UPDATE_REQUIRED',
  NotAccepted = 'NOT_ACCEPTED',
  NotLoggedIn = 'NOT_LOGGED_IN',
}

export const useConsent = (): { consentStatus: ConsentStatus; acceptConsent: () => void } => {
  const { isLoggedIn, user: currentUser } = useUserInfo();
  const [updateConsent] = useGiveConsent();
  const [isUpdatingConsent, setIsUpdatingConsent] = useState(false);
  const { data, loading, refetch } = useRutilusQuery<GetConsents, {}>(getConsents, {
    skip: !(isLoggedIn && currentUser),
  });

  const acceptConsent = async (): Promise<void> => {
    if (!isUpdatingConsent) {
      setIsUpdatingConsent(true);

      try {
        await Promise.all([
          updateConsent(CURRENT_CONSENT_PRIVACY_UUID),
          updateConsent(CURRENT_CONSENT_TERMS_UUID),
        ]);
        await refetch();
      } catch (error) {
        reportMykissError(error);
      }
      setIsUpdatingConsent(false);
    }
  };

  if (!isLoggedIn || loading || !data) {
    return { acceptConsent, consentStatus: ConsentStatus.NotLoggedIn };
  }

  const consents = getNodesFromConnection(data.me?.consents).filter(
    consent => consent.agreementUid !== CURRENT_CONSENT_MARKETING_EMAIL_UUID,
  );
  const privacy = consents.find(consent => consent.agreementUid === CURRENT_CONSENT_PRIVACY_UUID);
  const terms = consents.find(consent => consent.agreementUid === CURRENT_CONSENT_TERMS_UUID);

  if (privacy && privacy.accepted && terms && terms.accepted) {
    return { acceptConsent, consentStatus: ConsentStatus.Accepted };
  }
  if (consents.length === 0 || (privacy && terms)) {
    if (isNewUser(currentUser?.createdAt)) {
      acceptConsent().catch(reportMykissError);
      return { acceptConsent, consentStatus: ConsentStatus.NotLoggedIn };
    }
    return { acceptConsent, consentStatus: ConsentStatus.NotAccepted };
  }
  return { acceptConsent, consentStatus: ConsentStatus.UpdateRequired };
};
