import React from 'react';

import { IIconProps } from '../constants';

export const LinkedInIcon = ({
  width = 24,
  height = 24,
  title = '',
  ariaLabel = '',
  ariaHidden = false,
}: IIconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={ariaLabel}
    aria-hidden={ariaHidden}
  >
    {title && <title>{title}</title>}
    <path
      d="M20.4495 20.4497H16.8934V14.8805C16.8934 13.5525 16.8697 11.843 15.0439 11.843C13.1917 11.843 12.9083 13.2898 12.9083 14.7838V20.4492H9.35224V8.99688H12.766V10.5619H12.8139C13.509 9.37346 14.8062 8.66098 16.1822 8.71207C19.7864 8.71207 20.451 11.0828 20.451 14.1671L20.4495 20.4497ZM5.33975 7.43138C4.20765 7.43138 3.27564 6.4999 3.27564 5.36779C3.27564 4.23567 4.20722 3.30408 5.33932 3.30408C6.47131 3.30408 7.40278 4.23545 7.40299 5.36736C7.40299 6.49926 6.47164 7.43128 5.33975 7.43138ZM7.11777 20.4497H3.55797V8.99688H7.11777V20.4497ZM22.2223 0.00165575H1.77094C0.810915 -0.00918665 0.011701 0.771465 0 1.7315V22.2681C0.0112716 23.2287 0.810378 24.0102 1.77094 23.9999H22.2223C23.1852 24.012 23.9871 23.2306 24 22.2681V1.7301C23.9871 0.768029 23.1841 -0.0125153 22.2223 0.000152033V0.00165575Z"
      className="fill-current"
    />
  </svg>
);
