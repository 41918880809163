import { usePathname, useSearchParams } from 'next/navigation';

import { objectToQueryString, queryStringToObject } from '../../url/queryUtils';

interface URL {
  addParameters: (parameters: Record<string, string>) => string;
  currentPathName: string;
  hasParameters: (parameters: string[]) => boolean;
  removeParameters: (parameters: string[]) => string;
}

export const useCurrentURL = (): URL => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const urlQueryObject = queryStringToObject(searchParams?.toString());
  const { entries, fromEntries, prototype } = Object;

  const updateURLWithQueryParameters = (
    updatedUrlQueryObject: Record<string, string | Array<string | null> | null>,
  ): string => {
    const updatedUrlQueryString = objectToQueryString(updatedUrlQueryObject);
    const updatedPathName = `${pathname}${updatedUrlQueryString ? '?' : ''}`;

    return `${updatedPathName}${updatedUrlQueryString}`;
  };

  const addParameters = (parameters: Record<string, string>): string => {
    const updatedUrlQueryObject = fromEntries([...entries(urlQueryObject), ...entries(parameters)]);

    return updateURLWithQueryParameters(updatedUrlQueryObject);
  };

  const hasParameters = (parameters: string[]): boolean => {
    return parameters.every(parameter => prototype.hasOwnProperty.call(urlQueryObject, parameter));
  };

  const removeParameters = (parameters: string[]): string => {
    const updatedUrlQueryObject = fromEntries(
      entries(urlQueryObject).filter(([key]) => !parameters.includes(key)),
    );

    return updateURLWithQueryParameters(updatedUrlQueryObject);
  };

  return {
    addParameters,
    currentPathName: pathname || '',
    hasParameters,
    removeParameters,
  };
};
