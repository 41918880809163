import ContentLoader from 'react-content-loader';

interface IProps {
  orientation?: 'horizontal' | 'vertical';
  badgeWidth: number;
  badgeHeight: number;
}

const CONTENT_LOADER_KEY_PREFIX = 'appstorebuttons-loader-key';

export const AppStoreButtonsLoader = ({
  badgeWidth,
  badgeHeight,
  orientation = 'horizontal',
}: IProps): JSX.Element => {
  const verticalGap = 12; // gap-y-3
  const horizontalGap = 12; // gap-x-3

  if (orientation === 'vertical') {
    return (
      <>
        <ContentLoader
          uniqueKey={`${CONTENT_LOADER_KEY_PREFIX}-v-mobile`}
          className="flex sm:hidden"
          width={badgeWidth}
          height={badgeHeight}
        >
          <rect x="0" y="0" rx="5" ry="5" width={badgeWidth} height={badgeHeight} />
        </ContentLoader>
        <ContentLoader
          uniqueKey={`${CONTENT_LOADER_KEY_PREFIX}-v-desktop`}
          className="hidden sm:flex"
          width={badgeWidth}
          height={badgeHeight * 2 + verticalGap}
        >
          <rect x="0" y="0" rx="5" ry="5" width={badgeWidth} height={badgeHeight} />
          <rect
            x="0"
            y={badgeHeight + verticalGap}
            rx="5"
            ry="5"
            width={badgeWidth}
            height={badgeHeight}
          />
        </ContentLoader>
      </>
    );
  }
  return (
    <>
      <ContentLoader
        uniqueKey={`${CONTENT_LOADER_KEY_PREFIX}-h-mobile`}
        className="flex sm:hidden"
        width={badgeWidth}
        height={badgeHeight}
      >
        <rect x="0" y="0" rx="5" ry="5" width={badgeWidth} height={badgeHeight} />
      </ContentLoader>
      <ContentLoader
        uniqueKey={`${CONTENT_LOADER_KEY_PREFIX}-h-desktop`}
        className="hidden sm:flex"
        width={badgeWidth * 2 + horizontalGap}
        height={badgeHeight}
      >
        <rect x="0" y="0" rx="5" ry="5" width={badgeWidth} height={badgeHeight} />
        <rect
          x={badgeWidth + horizontalGap}
          y="0"
          rx="5"
          ry="5"
          width={badgeWidth}
          height={badgeHeight}
        />
      </ContentLoader>
    </>
  );
};
