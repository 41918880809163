import { ApolloError } from '@apollo/client';

import { useRutilusMutation } from '../../api/graphql/useRutilusMutation';
import { UpsertConsent, UpsertConsentVariables } from '../../interfaces/graphql';
import { upsertConsent } from '../../mutations/rutilus/UpsertConsent';

export const useGiveConsent = (): [
  (agreementUid: string, accepted?: boolean) => Promise<unknown>,
  ApolloError | undefined,
] => {
  const [upsertConsentMutation, { error: mutationError }] = useRutilusMutation<
    UpsertConsent,
    UpsertConsentVariables
  >(upsertConsent);

  const setConsent = async (agreementUid: string, accepted: boolean = true): Promise<unknown> =>
    upsertConsentMutation({
      variables: { input: { attributes: { accepted, agreementUid } } },
    });
  return [setConsent, mutationError];
};
