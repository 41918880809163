import React, { HTMLAttributes } from 'react';

import { twMerge } from 'tailwind-merge';

import { variants } from './variants';

export interface ITextProps extends HTMLAttributes<HTMLParagraphElement> {
  as?: string | React.ComponentType<any> | React.FunctionComponent<any>;
  variant?: keyof typeof variants;
  constant?: boolean;
}

export const Text = ({
  as: Tag = 'p',
  variant = 'md',
  className = '',
  constant,
  ...rest
}: ITextProps): JSX.Element => (
  <Tag
    className={twMerge(`${variants[variant]} ${constant ? 'constantSize' : ''}`, className)}
    {...rest}
  />
);
