import React from 'react';

import { ProPlanId } from '../../../../constants/premium';
import { useAnalytics } from '../../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackEvent } from '../../../../helpers/analytics/trackEvent';
import { useCurrentURL } from '../../../../helpers/hooks/useCurrentURL/useCurrentURL';
import { EventMykissCtaButtonClicked } from '../../../../interfaces/events';
import { URLParameters, flowsURLParameters } from '../../../../views/Authentication/constants';
import {
  URLParameters as onboardingURLParameters,
  companiesURLParameters as onboardingCompaniesURLParameters,
  flowsURLParameters as onboardingFlowsURLParameters,
} from '../../../../views/Onboarding/constants/url';
import { NonCrawlableLink } from '../../NonCrawlableLink/NonCrawlableLink';
import { ClickableSize, ClickableVariant } from '../../variants';

import styles from './CTAButton.module.css';

interface IProps {
  type: 'signup' | 'pro';
  proPlanId?: ProPlanId;
  /* A unique id for the location where the signup CTA is, e.g. "waters_sidebar", "blog_post_footer" */
  source: EventMykissCtaButtonClicked['properties']['source'];
  /* If the source is a datatype (e.g. a water, a species), then this is the external ID of that datatype */
  sourceId?: EventMykissCtaButtonClicked['properties']['source_id'];
  onClick?: (event: any | undefined) => void;
  children: React.ReactNode;
  className?: string;
  variant?: ClickableVariant;
  size?: ClickableSize;
  routerPushTo?: string;
}

/**
  CTAButton wraps common call to action buttons (signup and pro) and fires an analytics event when
  called. In the case of the 'pro' type it also prepends a Fishbrain Pro logo to the button.

  We use a non crawlable link here so that google bot cannot find and 'press' these buttons. If that 
  happens google will index all of the random query string urls in our crawl budget. 
 */

export const CTAButton = ({
  type,
  proPlanId,
  source,
  sourceId,
  onClick,
  children,
  className,
  routerPushTo,
  ...props
}: IProps): JSX.Element => {
  const { currentPage } = useAnalytics();
  const { addParameters } = useCurrentURL();

  const signupLink = addParameters({ [URLParameters.Flow]: flowsURLParameters.signUp });

  const becomeProLink = addParameters({
    [onboardingURLParameters.Company]: onboardingCompaniesURLParameters.fishbrain,
    [onboardingURLParameters.Flow]: onboardingFlowsURLParameters.fishbrain.becomePro,
  });

  switch (type) {
    case 'pro':
      return (
        <NonCrawlableLink
          routerPushTo={routerPushTo || becomeProLink}
          onClickEvent={event => {
            void trackEvent({
              name: 'mykiss_cta_button_clicked',
              properties: {
                type: 'pro',
                source,
                source_id: sourceId,
                page: currentPage,
              },
            });
            if (onClick) {
              onClick(event);
            }
          }}
          className={className}
          ariaLabel="become a Fishbrain pro member"
          {...props}
        >
          <div className={styles.starIcon} />
          {children}
        </NonCrawlableLink>
      );
    case 'signup':
      return (
        <NonCrawlableLink
          {...props}
          routerPushTo={signupLink}
          onClickEvent={event => {
            void trackEvent({
              name: 'mykiss_cta_button_clicked',
              properties: {
                source,
                source_id: sourceId,
                type: 'signup',
                page: currentPage,
              },
            });
            if (onClick) {
              onClick(event);
            }
          }}
          className={`${className} ${styles.ctaButton}`}
          ariaLabel="sign up for a free Fishbrain account"
          {...props}
        >
          {children}
        </NonCrawlableLink>
      );
    default:
      throw new Error(`Unknown type: ${type}`);
  }
};
