import React from 'react';

import { useAnalytics } from '../../../../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackEvent } from '../../../../../../helpers/analytics/trackEvent';
import {
  MOBILE_MENU_ICON_HEIGHT,
  MOBILE_MENU_ICON_WRAPPER_SIZE,
  MOBILE_MENU_LARGE_ICON_WIDTH,
} from '../../../constants/constants';

interface IProps {
  expanded: boolean;
  onExpandedChange: (value: boolean) => void;
}

export const HamburgerDropdownToggle = ({ expanded, onExpandedChange }: IProps): JSX.Element => {
  const { currentPage } = useAnalytics();

  return (
    <button
      type="button"
      className="grid grid-cols-1 grid-rows-1 items-center justify-center focus:outline-none sm:my-2"
      style={{ width: MOBILE_MENU_ICON_WRAPPER_SIZE, height: MOBILE_MENU_ICON_WRAPPER_SIZE }}
      onClick={() => {
        onExpandedChange(!expanded);
        void trackEvent({
          name: 'mykiss_hamburger_clicked',
          properties: { action: expanded ? 'dismiss' : 'open', source: currentPage },
        });
      }}
      data-testid="drop-down-menu"
      aria-label="main menu"
    >
      <div
        className={`col-start-1 row-start-1 flex transform cursor-pointer flex-col justify-between ${
          expanded ? 'scale-90' : 'scale-100'
        }`}
        style={{ height: MOBILE_MENU_ICON_HEIGHT, width: MOBILE_MENU_LARGE_ICON_WIDTH }}
      >
        <div
          className={`w-full origin-right transform rounded-sm transition-all ${
            expanded ? 'translate-x-0 translate-y-0 -rotate-45 bg-abyss' : 'bg-abyss'
          }`}
          style={{ height: 2 }}
        />
        <div
          className={`w-full origin-right transform rounded-sm bg-abyss transition-all ${
            expanded ? 'translate-x-full scale-0 opacity-0' : 'opacity-100'
          }`}
          style={{ height: 2 }}
        />
        <div
          className="w-full origin-right transform rounded-sm bg-abyss transition-all"
          style={{
            height: 2,
            transform: expanded
              ? // eslint-disable-next-line no-magic-numbers
                `translateY(${MOBILE_MENU_LARGE_ICON_WIDTH / 7.5}px) translateX(0) rotate(45deg)`
              : 'none',
          }}
        />
      </div>
    </button>
  );
};
