import { useUserInfo } from '../../../helpers/hooks/useUserInfo';
import { CTAButton } from '../../Clickables/Buttons/CTAButton/CTAButton';
import { Heading } from '../../Heading/Heading';
import { Text } from '../../Text/Text';

import greenDepth from './greenDepth.svg';

interface IProps {
  source: string;
  showLoggedIn?: boolean;
}

export const GreenDepthProCTA = ({ source, showLoggedIn = false }: IProps): JSX.Element => {
  const { isPremium, isLoggedIn } = useUserInfo();

  const show = !isPremium && (!isLoggedIn || (showLoggedIn && isLoggedIn));

  return show ? (
    <div
      style={{
        backgroundImage: `url(${greenDepth.src})`,
      }}
      className="w-full bg-cover py-6 sm:px-10 md:px-16 md:py-10"
    >
      <div className="flex flex-col items-center xl:container sm:flex-row sm:justify-between xl:mx-auto">
        <div className="sm:w-col-8 md:w-col-9 flex flex-col">
          <Heading variant="md" className="mx-12 text-center sm:ml-0 sm:mr-28 sm:text-left">
            Improve your fishing with Fishbrain Pro
          </Heading>
          <Text
            variant="md"
            className="mx-14 pt-2 text-center font-semibold sm:mb-0 sm:ml-0 sm:text-left"
          >
            Make your fishing dreams come true today and tomorrow
          </Text>
        </div>
        <CTAButton
          type="pro"
          source={source}
          variant="buttonPro"
          className="mb-2 mt-4 min-w-[220px]"
        >
          Learn more about Pro
        </CTAButton>
      </div>
    </div>
  ) : (
    <></>
  );
};
