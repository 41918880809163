import React from 'react';

import { usePathname, useSearchParams } from 'next/navigation';
import Script from 'next/script';

import { getPathWithWhitelistedQueryString } from '../../../helpers/analytics';

export const GoogleTagManager = (): JSX.Element => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const pathWithQueryString =
    pathname && searchParams ? getPathWithWhitelistedQueryString(pathname, searchParams) : '';

  return (
    <Script id="google-tag-manager" strategy="afterInteractive">
      {`
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
      originalLocation: document.location.protocol + '//' +
                      document.location.hostname +
                      '${pathWithQueryString}'
  });
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-W4CS7PZ');
`}
    </Script>
  );
};
