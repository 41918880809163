import React from 'react';

import { useAnalytics } from '../../../../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackEvent } from '../../../../../../helpers/analytics/trackEvent';
import { useCurrentURL } from '../../../../../../helpers/hooks/useCurrentURL/useCurrentURL';
import {
  URLParameters,
  flowsURLParameters,
} from '../../../../../../views/Authentication/constants';
import { CTAButton } from '../../../../../Clickables/Buttons/CTAButton/CTAButton';
import { NonCrawlableLink } from '../../../../../Clickables/NonCrawlableLink/NonCrawlableLink';

export const TopCTAs = (): JSX.Element => {
  const { currentPage } = useAnalytics();
  const { addParameters } = useCurrentURL();
  const loginLink = addParameters({ [URLParameters.Flow]: flowsURLParameters.login });

  return (
    <div className="px-6 pb-6 pt-2 sm:px-11 md:hidden" data-testid="TopCTAs">
      <div className="flex items-center justify-between sm:justify-start">
        <NonCrawlableLink
          routerPushTo={loginLink}
          variant="buttonSecondary"
          className="mr-3 w-1/2 rounded-lg text-base md:hidden"
          onClickEvent={() => {
            void trackEvent({
              name: 'mykiss_hamburger_clicked',
              properties: { action: 'navigate', source: currentPage },
            });
          }}
          ariaLabel="log in to Fishbrain"
        >
          Log in
        </NonCrawlableLink>
        <CTAButton
          type="signup"
          variant="buttonPrimary"
          source="navigation"
          className="w-1/2 rounded-lg text-base md:hidden"
          onClick={() => {
            void trackEvent({
              name: 'mykiss_hamburger_clicked',
              properties: { action: 'navigate', source: currentPage },
            });
          }}
        >
          Sign up
        </CTAButton>
      </div>
    </div>
  );
};
