import { ApolloError, ApolloQueryResult, QueryHookOptions, QueryOptions } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { DocumentNode, OperationDefinitionNode } from 'graphql';

import { isApolloNotFoundError, reportMykissError } from '../../helpers/errorHandling';
import { logger } from '../../helpers/logging';

export type GraphqlResponse<Query> = {
  notFound: boolean;
  error?: any;
  response?: ApolloQueryResult<Query>;
};

type APIName = 'rutilus';

function getOperationName(query: DocumentNode): string | undefined {
  const operationDefinition = query.definitions.find(d => d.kind === 'OperationDefinition');
  return operationDefinition
    ? (operationDefinition as OperationDefinitionNode).name?.value
    : undefined;
}

export function handleApolloErrorOnServer(
  error: any,
  options: QueryOptions,
  apiName: APIName,
): {
  notFound: boolean;
  error: any;
} {
  if (Error.captureStackTrace) {
    Error.captureStackTrace(error);
  }
  reportMykissError(error, undefined, undefined, {
    variables: options.variables,
    operationName: getOperationName(options.query),
    apiName,
    // For ServerParseError/ServerError
    bodyText: error?.networkError?.bodyText,
    statusCode: error?.networkError?.statusCode,
    responseHeaders: error?.networkError?.response?.headers,
  });

  // If the error is a 404 we return so that the page can return `notFound: true`,
  return { notFound: isApolloNotFoundError(error), error };
}

export function handleApolloErrorOnClient(
  error: ApolloError,
  query: DocumentNode,
  options: QueryHookOptions,
  apiName: APIName,
): void {
  if (Error.captureStackTrace) {
    Error.captureStackTrace(error);
  }
  reportMykissError(error, undefined, undefined, {
    variables: options.variables,
    operationName: getOperationName(query),
    apiName,
    // For ServerParseError/ServerError
    bodyText: (error?.networkError as any)?.bodyText,
    statusCode: (error?.networkError as any)?.statusCode,
    responseHeaders: (error?.networkError as any)?.response?.headers,
  });
}

const NON_RETRYABLE_ERRORS = ['ServerParseError'];

export function getRetryLink(apiName: APIName): RetryLink {
  return new RetryLink({
    attempts: {
      max: 5,
      retryIf: (error, _operation) => {
        logger.warn({ error }, 'Error while retrying');
        logger.warn({ error }, error.message);
        if (error && !NON_RETRYABLE_ERRORS.includes(error.name)) {
          reportMykissError(
            new Error(`Retrying apollo query ${_operation.operationName}`),
            undefined,
            'info',
            {
              operation: _operation.operationName,
              errorMessage: error.message,
              apiName,
            },
          );
        }
        return !!error;
      },
    },
  });
}
