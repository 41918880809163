import React, { useState } from 'react';

import Script from 'next/script';

import { useAnalytics } from '../../contexts/AnalyticsContext/AnalyticsContext';
import { trackEvent } from '../../helpers/analytics/trackEvent';
import { useIsMobileDevice } from '../../helpers/hooks/useIsMobileDevice';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

const HELPSHIFT_ZINDEX = 20;
const STICKY_BOTTOM_OFFSET = 88;

export const SupportChat = ({
  hasStickyBottomBanner = false,
  disableSupportChat = false,
}: {
  hasStickyBottomBanner?: boolean;
  disableSupportChat?: boolean;
}): JSX.Element => {
  const isMobileDevice = useIsMobileDevice();
  const text_trench = 'rgb(6, 29, 51)';
  const { currentPage } = useAnalytics();
  const [loadScript, setLoadScript] = useState(false);

  const track = (): void => {
    void trackEvent({
      name: 'mykiss_support_bubble_open',
      properties: { source: currentPage },
    });
  };
  return disableSupportChat ? (
    <></>
  ) : (
    <>
      {loadScript && (
        <Script id="helpshiftwidget">
          {`
        (function () {
          var PLATFORM_ID = "fishbrain_platform_20210413133229893-df6b5caf3d6247d",
          DOMAIN = "fishbrain",
          LANGUAGE = "en";
          window.helpshiftConfig = {
            platformId: PLATFORM_ID,
            domain: DOMAIN,
            language: LANGUAGE,
            widgetOptions: {
              showLauncher: false
            }
          };
        }) ();
        var widgetToggleEventHandler = function(data) {
          var launcher = document.getElementById('helpshiftLauncher');
          var loader = document.getElementById('helpshiftLoader');
          if (loader && data.visible) {
            loader.style.display = "none";
          }
          if (launcher && data.visible) {
            launcher.style.display = "none";
          } else if (launcher) {
            launcher.style.display = "block";
          }
        };
        !function(t,e){if("function"!=typeof window.Helpshift){var n=function(){n.q.push(arguments)};n.q=[],window.Helpshift=n;var i,a=t.getElementsByTagName("script")[0];if(t.getElementById(e))return;i=t.createElement("script"),i.async=!0,i.id=e,i.src="https://webchat.helpshift.com/webChat.js";var o=function(){window.Helpshift("init");window.Helpshift("addEventListener", "widgetToggle", widgetToggleEventHandler);window.Helpshift('open');};window.attachEvent?i.attachEvent("onload",o):i.addEventListener("load",o,!1),a.parentNode.insertBefore(i,a)}else window.Helpshift("update")}(document,"hs-chat");
        `}
        </Script>
      )}
      {loadScript && (
        <span
          className="z-20 fixed right-[18px] box-border h-[45px] w-[45px] cursor-pointer rounded-full px-[10px] pb-[8px] pt-[12px] outline-offset-[-4px]"
          id="helpshiftLoader"
          style={{
            outline: 'rgba(0, 103, 244, 0.4) solid 0px',
            background: text_trench,
            zIndex: HELPSHIFT_ZINDEX + 1,
            bottom: isMobileDevice && hasStickyBottomBanner ? `${STICKY_BOTTOM_OFFSET}px` : '18px',
          }}
        >
          <LoadingSpinner className="-ml-1 text-white" isMini />
        </span>
      )}
      <button
        type="button"
        id="helpshiftLauncher"
        aria-label="Open Chat"
        title="Support Chat"
        onClick={() => {
          track();
          if (window.Helpshift) {
            window.Helpshift('open');
          } else {
            setLoadScript(true);
          }
        }}
        className="z-20 fixed right-[18px] box-border h-[45px] w-[45px] cursor-pointer rounded-full px-[10px] pb-[8px] pt-[12px] outline-offset-[-4px]"
        style={{
          outline: 'rgba(0, 103, 244, 0.4) solid 0px',
          background: text_trench,
          zIndex: HELPSHIFT_ZINDEX,
          bottom: isMobileDevice && hasStickyBottomBanner ? `${STICKY_BOTTOM_OFFSET}px` : '18px',
        }}
      >
        <span className="absolute left-auto right-[4px] top-0 hidden h-5 w-5 rounded-full bg-error text-center text-xs leading-5 text-white" />
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 560 560">
            <g fill="#FFFFFF" transform="translate(60 77)">
              <path d="M363.373365,0 L10.16125,0 C5.42701923,0 0,3.96105769 0,8.62971154 L0,235.205385 C0,239.872981 5.42701923,244.326923 10.16125,244.326923 L70.8653846,244.326923 L70.8653846,338.349423 L166.193077,244.326923 L363.373365,244.326923 C368.108654,244.326923 371.25,239.872981 371.25,235.205385 L371.25,8.62971154 C371.25,3.96105769 368.107596, 0 363.373365,0 L363.373365,0 Z" />
              <path d="M431.957308,66.6346154 L397.692308,66.6346154 L397.692308,242.814423 C397.692308,259.72375 390.378365,270.769231 371.089231,270.769231 L179.909231,270.769231 L138.759712,312.019231 L275.929712,312.019231 L371.25,406.153846 L371.25,312.019231 L431.957308,312.019231 C436.693654,312.019231 440,307.511346 440,302.836346 L440,76.2627885 C440,71.5941346 436.693654,66.6346154 431.957308,66.6346154 L431.957308,66.6346154 Z" />
            </g>
          </svg>
        </span>
      </button>
    </>
  );
};
