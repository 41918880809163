import React from 'react';

import { DEFAULT_ICON_PROPS, IIconProps } from './constants';

export const CloseIcon = ({ ariaLabel = 'Close', ...props }: IIconProps): JSX.Element => {
  const { size, width, height, title, ariaHidden, className, strokeWidth } = {
    ...DEFAULT_ICON_PROPS,
    ...props,
  };

  return (
    <svg
      data-testid="CloseIcon"
      width={size || width}
      height={size || height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={ariaLabel}
      aria-hidden={ariaHidden}
      className={`block ${className}`}
    >
      {title && <title>{title}</title>}
      <path
        className="stroke-current"
        d="M18 6L6 18"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke-current"
        d="M6 6L18 18"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
