/* eslint-disable @typescript-eslint/no-floating-promises */
import React from 'react';

import { isDevelopment } from '../environment/isDevelopment';

const DEBOUNCE_MILLISECONDS = 1000;

export const useReactAxe = (clientHasRendered: boolean): void => {
  React.useEffect(() => {
    if (isDevelopment() && clientHasRendered) {
      // react-dom and @axe-core/react has been dynamically imported so that we'll
      // recieve warnings in our console about inaccessible code only in development environment

      import('react-dom').then(ReactDOM => {
        // eslint-disable-next-line import/no-extraneous-dependencies
        import('@axe-core/react').then(axe => {
          axe.default(React, ReactDOM, DEBOUNCE_MILLISECONDS);
        });
      });
    }
  }, [clientHasRendered]);
};
