// TODO FIB-40547
// check if and how we can re-add tests after Osano is implemented
import React, { ReactNode, useEffect, useState } from 'react';

import invariant from 'tiny-invariant';

import { isBrowser } from '../../helpers/environment/isBrowser';

export const OSANO_COOKIE_ACCEPTED = 'ACCEPT';
export const OSANO_COOKIE_DENIED = 'DENIED';

type OsanoCookiePermission = typeof OSANO_COOKIE_ACCEPTED | typeof OSANO_COOKIE_DENIED;

type OsanaCookiePermissions = {
  ANALYTICS: OsanoCookiePermission;
  ESSENTIAL: OsanoCookiePermission;
  MARKETING: OsanoCookiePermission;
  OPT_OUT: OsanoCookiePermission;
  PERSONALIZATION: OsanoCookiePermission;
  STORAGE: OsanoCookiePermission;
};

export const NO_PERMISSIONS: OsanaCookiePermissions = {
  ANALYTICS: OSANO_COOKIE_DENIED,
  ESSENTIAL: OSANO_COOKIE_ACCEPTED,
  MARKETING: OSANO_COOKIE_DENIED,
  OPT_OUT: OSANO_COOKIE_DENIED,
  PERSONALIZATION: OSANO_COOKIE_DENIED,
  STORAGE: OSANO_COOKIE_DENIED,
};

interface IState {
  permissions: OsanaCookiePermissions;
}

const CookiePermissionsContext = React.createContext<IState | null>(null);

interface IProps {
  children: ReactNode;
}

function CookiePermissionsProvider(props: IProps): JSX.Element {
  const [permissions, setPermissions] = useState<OsanaCookiePermissions>(NO_PERMISSIONS);

  useEffect(() => {
    if (isBrowser() && window.Osano) {
      setPermissions(window.Osano.cm.getConsent());
    }
  }, []);

  return (
    <CookiePermissionsContext.Provider
      {...props}
      value={{
        permissions,
      }}
    />
  );
}

const useCookiePermissions = (): IState => {
  const context = React.useContext(CookiePermissionsContext);
  invariant(context !== null);

  return context;
};

export { CookiePermissionsProvider, useCookiePermissions };
