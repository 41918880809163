/* eslint-disable fp/no-mutation, fp/no-let */
import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import merge from 'deepmerge';
import isEqual from 'lodash.isequal';

import { isBrowser } from '../../helpers/environment/isBrowser';

import { cacheTypePolicies } from './cacheTypePolicies';
import { initializeGraphqlClient } from './initializeGraphqlClient';

let browserClient: ApolloClient<NormalizedCacheObject> | undefined;

export function getRutilusGraphqlClient(
  initialCache: NormalizedCacheObject = {},
): ApolloClient<NormalizedCacheObject> {
  // CSR
  if (isBrowser()) {
    // eslint-disable-next-line no-underscore-dangle
    const _client =
      browserClient ??
      initializeGraphqlClient({
        cache: new InMemoryCache(cacheTypePolicies),
      });

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialCache) {
      // Get existing cache, loaded during client side data fetching
      const existingCache = _client.extract();
      // Merge the existing cache into data passed from getStaticProps/getServerSideProps
      const data = merge(initialCache, existingCache, {
        // combine arrays using object equality (like in sets)
        arrayMerge: (destinationArray, sourceArray) => [
          ...sourceArray,
          ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
        ],
      });
      _client.cache.restore(data);

      if (!browserClient) {
        browserClient = _client;
      }

      return browserClient;
    }
  }
  // SSR
  return initializeGraphqlClient({
    ssrMode: true,
  });
}
