import React from 'react';

import { useRouter } from 'next/navigation';

import { useAnalytics } from '../../../../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackNavigation } from '../../../../../../helpers/analytics';
import { trackEvent } from '../../../../../../helpers/analytics/trackEvent';
import { reportMykissError } from '../../../../../../helpers/errorHandling';
import { useUserInfo } from '../../../../../../helpers/hooks/useUserInfo';
import { ExternalLink } from '../../../../../Clickables/ExternalLink/ExternalLink';
import { InternalLink } from '../../../../../Clickables/InternalLink/InternalLink';
import { Heading } from '../../../../../Heading/Heading';

import { BrandMenu } from './BrandMenu';

interface IProps {
  onExpandedChange: (value: boolean) => void;
}

export const SecondaryDropDownLinks = ({ onExpandedChange }: IProps): JSX.Element => {
  const { user } = useUserInfo();
  const { push } = useRouter();
  const { currentPage } = useAnalytics();

  const navigateTo = (path: string): void => {
    onExpandedChange(false);
    try {
      push(path);
    } catch (error) {
      reportMykissError(error);
    }
  };

  return (
    <div
      className="flex flex-col px-6 pt-4 sm:px-11 sm:pt-6 md:px-6 md:pt-4"
      data-testid="SecondaryDropDownLinks"
    >
      <Heading variant="xs" as="div" className="m-0 mb-4 text-abyss sm:mb-6 md:mb-4 md:text-base">
        <InternalLink
          to={user?.isPremium ? '/pro/overview' : '/pro'}
          variant="textNone"
          className="flex items-center text-pike-shade"
          onClick={() => {
            onExpandedChange(false);
            void trackEvent({
              name: 'mykiss_cta_button_clicked',
              properties: {
                type: 'pro',
                source: 'navigation',
                page: currentPage,
              },
            });
          }}
        >
          Fishbrain Pro
        </InternalLink>
      </Heading>
      <Heading variant="xs" as="div" className="m-0 mb-4 text-abyss sm:mb-6 md:mb-4 md:text-base">
        <InternalLink
          to="/mission"
          variant="textNone"
          className="flex items-center"
          onClick={() => {
            onExpandedChange(false);
            trackNavigation({ path: 'mission', currentPage });
            void trackEvent({
              name: 'mykiss_hamburger_clicked',
              properties: { action: 'navigate', source: currentPage },
            });
          }}
        >
          About Fishbrain
        </InternalLink>
      </Heading>
      <Heading variant="xs" as="div" className="m-0 mb-4 text-abyss sm:mb-6 md:mb-4 md:text-base">
        <ExternalLink
          href="https://fishbrain.helpshift.com/a/fishbrain---social-fishing-forecast-app/"
          variant="textNone"
          className="flex items-center"
          onClick={() => {
            trackNavigation({ path: 'support', currentPage });
          }}
        >
          Support
        </ExternalLink>
      </Heading>
      <Heading variant="xs" as="div" className="m-0 mb-4 text-abyss sm:mb-6 md:mb-4 md:text-base">
        <InternalLink
          to="/log-a-catch"
          variant="textNone"
          className="flex items-center"
          onClick={() => {
            onExpandedChange(false);
            trackNavigation({ path: 'log-a-catch', currentPage });
            void trackEvent({
              name: 'mykiss_hamburger_clicked',
              properties: { action: 'navigate', source: currentPage },
            });
          }}
        >
          Log a catch
        </InternalLink>
      </Heading>
      {!!user && <BrandMenu navigateTo={navigateTo} onExpandedChange={onExpandedChange} />}
    </div>
  );
};
