/* eslint-disable max-classes-per-file */
import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

export class ApiError extends Error {
  url: string;

  statusCode: number;

  bodyText: string;

  constructor(response: Response, bodyText: string) {
    super();
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = 'ApiError';
    this.url = response.url;
    this.statusCode = response.status;
    this.bodyText = bodyText;

    try {
      const { errors } = JSON.parse(bodyText);
      // eslint-disable-next-line prefer-destructuring
      this.message = errors[0];
    } catch {
      this.message = bodyText;
    }
  }
}

export class NotFoundError extends Error {
  constructor(message: string, originalError?: Error) {
    super(message);
    this.name = 'NotFoundError';
    if (originalError) {
      this.stack = originalError.stack;
    }
  }
}

// A generic graphql error. Used to wrap around ApolloError so that we have an actual name we can
// use to filter on in Bugsnag
export class MykissApolloError extends Error {
  graphQLErrors: unknown;

  networkError: unknown;

  extraInfo: unknown;

  constructor(originalError: ApolloError) {
    super();
    this.graphQLErrors = originalError.graphQLErrors;
    this.networkError = originalError.networkError;
    this.extraInfo = originalError.extraInfo;
    this.name = 'MykissApolloError';
    this.stack = originalError.stack;
    this.message = originalError.message;
  }
}

export class MykissApolloNotFoundError extends NotFoundError {
  graphQLErrors: unknown;

  networkError: unknown;

  extraInfo: unknown;

  constructor(message: string, originalError?: ApolloError) {
    super(message, originalError);
    if (originalError) {
      this.graphQLErrors = originalError.graphQLErrors;
      this.networkError = originalError.networkError;
      this.extraInfo = originalError.extraInfo;
    }
    this.name = 'MykissApolloNotFoundError';
  }
}

// A generic graphql error. Used to wrap around GraphQLError so that we have an actual name we can
// use to filter on in Bugsnag
export class MykissGraphQLError extends Error {
  path: unknown;

  locations: unknown;

  constructor(originalError: GraphQLError) {
    super();
    this.locations = originalError.locations;
    this.path = originalError.path;
    this.name = 'MykissGraphQLError';
    this.stack = originalError.stack;
    this.message = originalError.message;
  }
}
