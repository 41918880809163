import React, { useState } from 'react';

import Image from 'next/image';
import { usePathname } from 'next/navigation';

import { useAnalytics } from '../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackNavigation } from '../../../helpers/analytics';
import { trackEvent } from '../../../helpers/analytics/trackEvent';
import { useCurrentURL } from '../../../helpers/hooks/useCurrentURL/useCurrentURL';
import { useUserInfo } from '../../../helpers/hooks/useUserInfo';
import { URLParameters, flowsURLParameters } from '../../../views/Authentication/constants';
import { CTAButton } from '../../Clickables/Buttons/CTAButton/CTAButton';
import { InternalLink } from '../../Clickables/InternalLink/InternalLink';
import { NonCrawlableLink } from '../../Clickables/NonCrawlableLink/NonCrawlableLink';
import { FeatureFlagNotification } from '../../FeatureFlagNotification/FeatureFlagNotification';
import { Heading } from '../../Heading/Heading';
import { SearchIcon } from '../../Icons/SearchIcon';
import { Logo } from '../../Logo/Logo';
import { SearchBox } from '../../SearchBox/SearchBox';

import activeLinkIcon from './assets/activeLinkIconBottom.svg';
import { DropDownMenu } from './components/DropDownMenu/DropDownMenu';
import { DESKTOP_MENU_ICON_HEIGHT, DESKTOP_MENU_ICON_WIDTH } from './constants/constants';
import { MAIN_NAV_LINKS, INavLinkItem } from './constants/navlinks';

/**
 * The **MainNavigation** component implements the main navigation of the app.
 * Through it, all sections and their variations are defined.
 * @returns A JSX element.
 */
export const MainNavigation = (): JSX.Element => {
  const pathname = usePathname();
  const isBrands: boolean = pathname === '/brands/all';
  const { addParameters } = useCurrentURL();
  const loginLink = addParameters({ [URLParameters.Flow]: flowsURLParameters.login });

  const { currentPage } = useAnalytics();
  const [expanded, setExpanded] = useState(false);

  const [isSearchBoxActive, setIsSearchBoxActive] = useState(false);
  const { isLoggedIn } = useUserInfo();

  const handleExpandedChange = (value: boolean): void => {
    setExpanded(value);
  };

  return (
    <header
      className="sticky top-0 z-nav min-h-[var(--main-navigation-height-mobile)] w-full items-center border-b border-b-twilight bg-white sm:min-h-[var(--main-navigation-height)]"
      data-testid="MainNavigation"
    >
      <FeatureFlagNotification />
      <nav
        className={`relative flex items-center ${
          expanded ? 'justify-end md:justify-between' : 'justify-between'
        } min-h-[var(--main-navigation-height-mobile)] px-6 py-3 sm:min-h-[var(--main-navigation-height)] md:px-14 md:py-2.5 lg:px-16 xl:mx-auto`}
      >
        {!isBrands && isSearchBoxActive ? (
          <div className="ml-2 block flex-grow">
            <SearchBox
              placeholder="Search on Fishbrain..."
              width="100%"
              widthMenu="100%"
              className="h-8 sm:h-10"
              parent="main-navigation"
              showCloseBtn
              onCloseSearchBox={() => {
                setExpanded(false);
                setIsSearchBoxActive(false);
              }}
            />
          </div>
        ) : (
          <>
            <div className="flex items-center">
              <div className={`m-px md:w-48 lg:mx-0 ${expanded ? 'hidden md:block' : ''}`}>
                <Logo />
              </div>
              <div className="hidden md:block">
                <ul className="m-0 flex items-center md:pl-16">
                  {MAIN_NAV_LINKS.map(({ path, title, id, subPages }: INavLinkItem) => {
                    const isActiveSubPage = subPages?.filter(page => pathname?.includes(page));
                    const isActiveTab = pathname?.includes(path) || isActiveSubPage?.length;
                    return (
                      <li key={`dropdown-secondary-nav-link-${id}`} className="m-0">
                        <InternalLink
                          variant="textNone"
                          to={path}
                          className="flex items-center"
                          onClick={() => {
                            trackNavigation({ path, currentPage });
                          }}
                        >
                          <Heading
                            as="span"
                            withDefaults={false}
                            variant="2xs"
                            color={isActiveTab ? 'navHighlight' : 'dark'}
                            className="flex flex-col items-center px-3 font-heading text-xl hover:text-pinktail-shade"
                          >
                            {title}
                            <Image
                              alt="Active Link Icon"
                              width={21}
                              height={6}
                              className={`my-auto ${isActiveTab ? 'absolute bottom-0' : 'hidden'}`}
                              src={activeLinkIcon}
                            />
                          </Heading>
                        </InternalLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="flex items-center justify-between space-x-6 sm:justify-center">
              <button
                aria-label="main search"
                className={`p-0.5 ${expanded ? 'hidden md:block' : ''}`}
                data-testid="main-search-icon"
                onClick={() => setIsSearchBoxActive(true)}
                type="button"
              >
                <SearchIcon width={DESKTOP_MENU_ICON_WIDTH} height={DESKTOP_MENU_ICON_HEIGHT} />
              </button>

              {!isLoggedIn && (
                <>
                  <div
                    className="hidden items-center justify-start md:flex"
                    data-testid="desktop log in button"
                  >
                    <NonCrawlableLink
                      variant="buttonSecondary"
                      size="sm"
                      className="px-3 sm:px-5"
                      routerPushTo={loginLink}
                      onClickEvent={() => {
                        void trackEvent({
                          name: 'mykiss_hamburger_clicked',
                          properties: { action: 'navigate', source: currentPage },
                        });
                      }}
                      ariaLabel="log in to Fishbrain"
                    >
                      Log in
                    </NonCrawlableLink>
                  </div>
                  <div className="hidden items-center justify-start md:flex">
                    <CTAButton
                      type="signup"
                      variant="buttonPrimary"
                      source="navigation"
                      size="sm"
                      className="mr-3 rounded-lg px-3 sm:px-5"
                    >
                      Sign up
                    </CTAButton>
                  </div>
                </>
              )}
              <div className="relative block self-center">
                <DropDownMenu expanded={expanded} onExpandedChange={handleExpandedChange} />
              </div>
            </div>
          </>
        )}
      </nav>
    </header>
  );
};
