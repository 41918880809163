import React, { HTMLAttributes } from 'react';

import { twMerge } from 'tailwind-merge';

export const ModalBody = ({
  className = '',
  ...rest
}: HTMLAttributes<HTMLDivElement>): JSX.Element => (
  <section className={twMerge('p-6 sm:p-10', className)} {...rest} />
);
