import isNode from 'detect-node';
import { ReadonlyURLSearchParams } from 'next/navigation';

import { PAGE_TITLE_SUFFIX } from '../constants/constants';
import { URLParameters } from '../views/Onboarding/constants/url/urlParameters';

import { isDatadogSynthetics } from './analytics/isDatadogSynthetics';
import { trackEvent } from './analytics/trackEvent';
import { isProduction } from './environment/isProduction';
import { objectToQueryString, queryStringToObject } from './url/queryUtils';

/* eslint-disable-next-line fp/no-let */
let previousPathWithQueryString: undefined | string;

const PAID_SEARCH_PARAMS = ['gclid', 'utm_source', 'utm_medium', 'utm_campaign', 'fbclid'];
const ONBOARDING_PARAMS = [URLParameters.Company, URLParameters.Flow];

const WHITE_LISTED_QUERY_STRINGS = [...ONBOARDING_PARAMS, ...PAID_SEARCH_PARAMS];

export function getPathWithWhitelistedQueryString(
  pathname: string,
  searchParams: ReadonlyURLSearchParams,
): string {
  const queryStringObj = queryStringToObject(searchParams.toString());
  const whiteListedValues = WHITE_LISTED_QUERY_STRINGS.reduce((whitelisted, key) => {
    if (queryStringObj[key] !== undefined) {
      return { ...whitelisted, [key]: queryStringObj[key] };
    }
    return whitelisted;
  }, {});
  const whitelistedQueryString = objectToQueryString(whiteListedValues);

  return whitelistedQueryString.length ? `${pathname}?${whitelistedQueryString}` : pathname;
}

export const pageEvent = (
  pathname: string,
  searchParams: ReadonlyURLSearchParams,
  title: string,
): void => {
  const pathWithQueryString = getPathWithWhitelistedQueryString(pathname, searchParams);

  // Google Analytics page view
  if (previousPathWithQueryString !== pathWithQueryString) {
    // @ts-ignore:
    if (!isNode && window.dataLayer && isProduction() && !isDatadogSynthetics()) {
      // @ts-ignore:
      window.dataLayer.push({
        event: 'routeChange',
        pagePath: pathWithQueryString,
        pageTitle: `${title}${PAGE_TITLE_SUFFIX}`,
      });
    }
  }

  /* eslint-disable-next-line fp/no-mutation */
  previousPathWithQueryString = pathWithQueryString;
};

export const trackNavigation = ({
  path,
  currentPage,
}: {
  path: string;
  currentPage: string;
}): void => {
  void trackEvent({
    name: 'mykiss_navigation_clicked',
    properties: { destination: path, source: currentPage, level: 'l1' },
  });
};
