import { CTAButton } from '../../Clickables/Buttons/CTAButton/CTAButton';
import { Text } from '../../Text/Text';

export const AnglerSignUpCTA = (): JSX.Element => (
  <div className="w-full flex-grow py-4 pl-3">
    <div className="mb-2 text-sm font-semibold uppercase tracking-wider text-midnight">Anglers</div>
    <Text variant="sm" className="mb-2">
      Sign up to search for anglers
    </Text>
    <CTAButton type="signup" source="search-bar" variant="textPrimaryDarkBg" className="pt-2">
      <Text variant="sm" className="mb-0 font-semibold">
        Sign up for free
      </Text>
    </CTAButton>
  </div>
);
