import React from 'react';

import { ApolloError } from '@apollo/client';
import Bugsnag, { Event } from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import isNode from 'detect-node';
import { GraphQLError } from 'graphql';

import { config } from '../../config/bugsnag.config';
import { UNIVERSAL_ENV_VARS } from '../constants/environmentVariables';
import { GetCurrentUser_currentUser } from '../interfaces/graphql';

import { getMykissEnv } from './environment/getMykissEnv';
import { isBrowser } from './environment/isBrowser';
import {
  ApiError,
  MykissApolloError,
  MykissApolloNotFoundError,
  MykissGraphQLError,
} from './errors';
import { logger } from './logging';

function log(report: Event): void {
  if (report.severity === 'error') {
    logger.error(report.originalError);
  } else if (report.severity === 'warning') {
    logger.warn(report.originalError);
  } else {
    logger.info(report.originalError);
  }
}

const releaseStage = getMykissEnv();
const bugsnagClient = Bugsnag.start({
  apiKey: UNIVERSAL_ENV_VARS.BUGSNAG_API_KEY,
  releaseStage,
  appVersion: UNIVERSAL_ENV_VARS.COMMIT_SHA,
  plugins: [new BugsnagPluginReact(React)],
  onError: function onError(report) {
    if (report?.request?.url) {
      // eslint-disable-next-line fp/no-mutation, no-param-reassign
      report.request.decodedUrl = decodeURI(report.request.url);
    }
    // Output the error to console if running node in a dev environment.
    if (!config.stages.includes(releaseStage)) {
      log(report);
      return false;
    }

    if (!isBrowser()) {
      log(report);
    }

    return true;
  },
});

export function setErrorReportingUser(user?: GetCurrentUser_currentUser): void {
  bugsnagClient.setUser(
    user ? user._id : undefined,
    user && user.privateFields.email ? user.privateFields.email : undefined,
    user ? user.nickname : undefined,
  );
}

function getContext(): string | undefined {
  return isNode ? undefined : window?.location?.pathname;
}

function reportAPIError(
  error: Response,
  severity?: 'info' | 'warning',
  otherData?: {},
  context: string | undefined = getContext(),
): void {
  error
    .text()
    .then((errorText: string) => {
      bugsnagClient.notify(new ApiError(error, errorText), event => {
        event.addMetadata('response', {
          responseStatus: error.status,
          responseStatusText: error.statusText,
          responseErrorText: errorText,
          url: error.url,
          decodedUrl: decodeURI(error.url),
        });
        event.addMetadata('headers', { headers: error.headers });
        if (otherData) {
          event.addMetadata('debug', otherData);
        }
        // eslint-disable-next-line fp/no-mutation, no-param-reassign
        event.context = context;
        // eslint-disable-next-line fp/no-mutation, no-param-reassign
        event.severity = severity || 'error';
        // eslint-disable-next-line fp/no-mutation, no-param-reassign
        event.groupingHash = `${context}-${error.status}-${errorText}`;
      });
    })
    .catch(bugsnagClient.notify);
}

export function isApolloNotFoundError(error: ApolloError): boolean {
  return (
    error.message.startsWith(`GraphQL error: Couldn't find`) ||
    error.message.startsWith("Couldn't find `User` with given arguments") ||
    error.message.startsWith("Couldn't find `FishingWater` with given arguments") ||
    error.message.startsWith("Couldn't find `Place` with given arguments") ||
    error.message.startsWith("Couldn't find `Op::Post` with given arguments") ||
    error.message.startsWith("couldn't understand how to find `Op::Post` with id") ||
    error.message.startsWith("Couldn't find `Species` with given arguments") ||
    error.message.startsWith("couldn't understand how to find `Species` with id") ||
    error.message.startsWith("Couldn't find `Region` with `id`") ||
    error.message.startsWith("Couldn't find `Equipment::Product` with given arguments") ||
    error.message.startsWith("Couldn't find `Catch` with `ID`")
  );
}

const convertApolloError = (error: ApolloError): MykissApolloError | MykissApolloNotFoundError => {
  if (isApolloNotFoundError(error)) {
    return new MykissApolloNotFoundError(error.message, error);
  }
  return new MykissApolloError(error);
};

function getGraphQLGroupingHash(message: string): string {
  // This particular error contains ids and gets spread out in Bugsnag.
  if (message.startsWith("Couldn't find Taxon")) {
    return "Couldn't find Taxon";
  }

  // Bugsnag will group errors by surrounding code in the stack trace. For apollo errors that's
  // always code internal to the Apollo libraries, so all our GQL errors get grouped together.
  // To get better grouping we're instead just grouping based on the error message.
  return message;
}

function reportApolloError(
  error: ApolloError,
  severity?: 'info' | 'warning',
  otherData?: {},
  context: string | undefined = getContext(),
): void {
  const convertedError = convertApolloError(error);
  bugsnagClient.notify(convertedError, event => {
    event.addMetadata('ApolloError', {
      extraInfo: convertedError.extraInfo,
      graphQLErrors: convertedError.graphQLErrors,
      networkError: convertedError.networkError,
    });
    if (otherData) {
      event.addMetadata('debug', otherData);
    }
    // eslint-disable-next-line fp/no-mutation, no-param-reassign
    event.context = context;

    if (convertedError.name === 'MykissApolloNotFoundError') {
      // eslint-disable-next-line fp/no-mutation, no-param-reassign
      event.severity = severity || 'info';
    } else {
      // eslint-disable-next-line fp/no-mutation, no-param-reassign
      event.severity = severity || 'error';
    }
    // eslint-disable-next-line fp/no-mutation, no-param-reassign
    event.groupingHash = getGraphQLGroupingHash(convertedError.message);
  });
}

export function reportGraphQLError(
  error: GraphQLError,
  severity?: 'info' | 'warning',
  otherData?: {},
  context: string | undefined = getContext(),
): void {
  const convertedError = new MykissGraphQLError(error);
  bugsnagClient.notify(convertedError, event => {
    event.addMetadata('MykissGraphQLError', {
      locations: convertedError.locations,
      path: convertedError.path,
      message: convertedError.message,
    });
    if (otherData) {
      event.addMetadata('debug', otherData);
    }
    // eslint-disable-next-line fp/no-mutation, no-param-reassign
    event.context = context;
    // eslint-disable-next-line fp/no-mutation, no-param-reassign
    event.severity = severity || 'error';
    // eslint-disable-next-line fp/no-mutation, no-param-reassign
    event.groupingHash = getGraphQLGroupingHash(convertedError.message);
  });
}

export function reportMykissError(
  error: Error | Response,
  context?: string,
  severity?: 'info' | 'warning',
  otherData?: {},
): void {
  // The below check of error.text is a replacement for `error instanceof Response`, as the Response
  // class is not available in the Node environment. A Fetch API response should have a `text`
  // function (we use it in `reportAPIError`), so we use it's existance to decide the error is from
  // a Fetch request.
  // @ts-ignore
  if (error && error.text) {
    reportAPIError(error as Response, severity, otherData, context);
  } else if (error instanceof ApolloError) {
    reportApolloError(error, severity, otherData, context);
  } else if (error instanceof GraphQLError) {
    reportGraphQLError(error, severity, otherData, context);
  } else {
    bugsnagClient.notify(error as Error, event => {
      // eslint-disable-next-line fp/no-mutation, no-param-reassign
      event.context = context;
      if ((error as Error).name === 'NotFoundError') {
        // eslint-disable-next-line fp/no-mutation, no-param-reassign
        event.severity = severity || 'info';
      } else {
        // eslint-disable-next-line fp/no-mutation, no-param-reassign
        event.severity = severity || 'error';
      }

      if (otherData) {
        event.addMetadata('other', otherData);
      }
      return true;
    });
  }
}

const FailedErrorBoundary = (props: { children?: React.ReactNode }): JSX.Element => (
  <React.Fragment {...props} />
);

export function getReactErrorBoundary(): BugsnagErrorBoundary {
  const plugin = bugsnagClient.getPlugin('react');
  if (!plugin) {
    reportMykissError(
      new Error('Couldnt load ErrorBoundary as Bugsnag has no React plugin'),
      undefined,
      'warning',
    );
    return FailedErrorBoundary;
  }
  return plugin.createErrorBoundary();
}
