import React from 'react';

import { IIconProps } from './constants';

export const StatsIcon = ({
  size = undefined,
  height = 24,
  width = 24,
  strokeWidth = 2,
  title = '',
  ariaLabel = '',
  ariaHidden = false,
}: IIconProps): JSX.Element => (
  <svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={ariaLabel}
    aria-hidden={ariaHidden}
  >
    {title && <title>{title}</title>}
    <path
      d="M2 4.55035V19.0504C2 19.6026 2.44772 20.0504 3 20.0504H21.5"
      stroke="currentcolor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M19 6.5L13 14L9 11L5.5 15"
      stroke="currentcolor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7602 6.55908L19.3881 6.02964L19.9176 10.6576"
      stroke="currentcolor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
