/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { AnchorHTMLAttributes, MouseEventHandler } from 'react';

import { useAnalytics } from '../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackEvent } from '../../../helpers/analytics/trackEvent';
import { getDomain, getUrlHostname } from '../../../helpers/urls';
import { getVariantClass, IClickableProps } from '../variants';

interface IProps extends AnchorHTMLAttributes<HTMLAnchorElement>, IClickableProps {
  onClick?: MouseEventHandler<any>;
  disabled?: boolean;
  href: string;
}

export const ExternalLink = ({
  onClick,
  children,
  className = '',
  variant = 'textPrimarySans',
  size,
  disabled = false,
  ...rest
}: IProps): JSX.Element => {
  const { currentPage } = useAnalytics();
  const onClickWithTracking = (event: any): void => {
    event.stopPropagation();

    if (onClick) {
      onClick(event);
    }
    // TODO: This check is only required for the Next migration
    if (!rest.href.startsWith(getDomain()) && !rest.href.startsWith('/')) {
      void trackEvent({
        name: 'mykiss_outbound_link_clicked',
        properties: {
          url: rest.href,
          current_page: currentPage,
          hostname: getUrlHostname(rest.href),
        },
      });
    }
  };

  if (disabled) {
    // This throws a type error because span shouldn't get the event listeners, but in this case
    // were fine with it as the intention is that they should do nothing.

    // classes applied manually since span won't recognized disabled prop
    if (variant.includes('text')) {
      return (
        <span
          onClick={() => {}}
          className={`${className} ${getVariantClass(
            variant,
            size,
          )} pointer-events-none cursor-not-allowed text-disabled transition-colors`}
          {...rest}
        >
          {children}
        </span>
      );
    }
    if (variant.includes('button')) {
      return (
        <span
          onClick={() => {}}
          className={`${className} ${getVariantClass(
            variant,
            size,
          )} pointer-events-none cursor-not-allowed bg-disabled text-twilight`}
          style={{ backgroundColor: 'rgb(157 161 166)' }}
          {...rest}
        >
          {children}
        </span>
      );
    }
    // @ts-ignore
    return <span className={`${className} ${getVariantClass(variant, size)}`} {...rest} />;
  }

  return (
    <a
      onClick={onClickWithTracking}
      className={`${className} ${getVariantClass(variant, size)}`}
      {...rest}
    >
      {children}
    </a>
  );
};
