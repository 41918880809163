import React from 'react';

import { IClickableProps, getVariantClass } from '../variants';

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    IClickableProps {}

export const Button = ({
  className = '',
  size = 'default',
  variant = 'buttonPrimary',
  ...rest
}: IButtonProps): JSX.Element => (
  <button
    type="button"
    className={`${className} ${getVariantClass(variant, size)} text-base`}
    {...rest}
  />
);
