import { useAuth } from '../../contexts/AuthContext';
import { GetCurrentUser_currentUser } from '../../interfaces/graphql';
import { useClientHasRendered } from '../hooks';

export type CurrentUser = GetCurrentUser_currentUser | void;

export interface UseUserInfo {
  isLoggedIn: boolean;
  isPremium: boolean;
  isVerified: boolean;
  user: CurrentUser;
  premiumEndDate: CurrentUser | undefined;
  isLoadingUser: boolean;
  refetchCurrentUser: () => void;
}

export function useUserInfo(): UseUserInfo {
  const { isLoggedIn, currentUser, refetchCurrentUser, isLoadingUser, premiumEndDate } = useAuth();
  const clientHasRendered = useClientHasRendered();

  return {
    isLoadingUser: clientHasRendered && isLoadingUser,
    isLoggedIn: clientHasRendered && isLoggedIn,
    isPremium: !!currentUser?.isPremium,
    isVerified: !!currentUser?.isVerified,
    user: currentUser,
    premiumEndDate,
    refetchCurrentUser,
  };
}
