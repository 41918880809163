import { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum-slim';
import { useSearchParams } from 'next/navigation';

import { getIsUA } from '../../../helpers/analytics/getIsUa';
import { getReferringDomain } from '../../../helpers/analytics/getReferringDomain';
import { trackEvent } from '../../../helpers/analytics/trackEvent';
import { isBrowser } from '../../../helpers/environment/isBrowser';
import { useCMSSlug } from '../../../helpers/hooks/useCMSSlug';
import { useIsShop } from '../../../helpers/hooks/useIsShop';
import { logger } from '../../../helpers/logging';
import { QueryStringKey } from '../../../helpers/url/queryUtils';

type PageViewProps = {
  page: string;
  pageTitle: string;
  pageId?: string;
  pageSubject?: string;
  previousPage: string | null;
  blockDatadogRum?: boolean;
};

export function usePageView({
  page,
  pageId,
  pageSubject,
  previousPage,
  blockDatadogRum,
}: PageViewProps): void {
  const searchParams = useSearchParams();
  const cmsSlug = useCMSSlug();
  const isShop = useIsShop();

  useEffect(() => {
    if (isBrowser()) {
      logger.debug({ page, pageId }, `Page viewed`);
      const isFirstPage = previousPage === null;
      if (!blockDatadogRum) {
        datadogRum.startView(page);
      }

      void trackEvent({
        name: 'mykiss_page_viewed',
        properties: {
          page,
          ...(previousPage ? { prev_page: previousPage } : {}),
          is_first_page_viewed: isFirstPage,
          page_id: pageId,
          page_subject: pageSubject,
          is_shop: isShop,
          page_slug: cmsSlug,
        },
      });

      if (isFirstPage) {
        void trackEvent({
          name: 'mykiss_first_page_viewed',
          properties: {
            page,
            referrer: document ? document.referrer : undefined,
            rootReferrer:
              document && document.referrer && document.referrer.length
                ? getReferringDomain(document.referrer)
                : undefined,
            isUA: searchParams ? getIsUA(searchParams) : false,
            is_shop: isShop,
            campaign: searchParams?.get(QueryStringKey.Campaign) || undefined,
            page_slug: cmsSlug,
          },
        });
      }
    }
    // We explicitly only want this hook to rerun if the page or pageId change (indicating a change
    // in the current route).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageId]);
}
