export interface INavLinkItem {
  id: string;
  title: string;
  type: 'internal' | 'external' | 'render';
  path: string;
  subPages?: string[];
  target: 'blank' | 'self';
  icon?: string;
  iconActive?: string;
  Component?: () => JSX.Element;
}

export const MAIN_NAV_LINKS: INavLinkItem[] = [
  {
    id: 'app',
    title: 'App',
    type: 'internal',
    path: '/features',
    target: 'self',
  },
  {
    id: 'map',
    title: 'Map',
    type: 'internal',
    path: '/explore',
    target: 'self',
  },
  {
    id: 'discover',
    title: 'Discover',
    type: 'internal',
    path: '/discover',
    subPages: ['/fishing-waters/popular', '/countries/us-united-states' /*  '/cities/us' */],
    target: 'self',
  },
  {
    id: 'blog',
    title: 'Blog',
    type: 'internal',
    path: '/blog',
    target: 'self',
  },
];
