import { useSearchParams } from 'next/navigation';

import { QueryStringKey, queryStringToObject } from '../url/queryUtils';

// TODO this might be redundant, replace with useSearchParams
export function useQueryString(
  key: QueryStringKey,
): string | Array<string | null> | null | undefined {
  const searchParams = useSearchParams();
  return queryStringToObject(searchParams?.toString())[key];
}
