import React from 'react';

import Image from 'next/image';
import { usePathname } from 'next/navigation';

import { useAnalytics } from '../../../../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackNavigation } from '../../../../../../helpers/analytics';
import { trackEvent } from '../../../../../../helpers/analytics/trackEvent';
import { ExternalLink } from '../../../../../Clickables/ExternalLink/ExternalLink';
import { InternalLink } from '../../../../../Clickables/InternalLink/InternalLink';
import { Heading } from '../../../../../Heading/Heading';
import activeLinkIcon from '../../../assets/activeLinkIcon.svg';
import { INavLinkItem, MAIN_NAV_LINKS } from '../../../constants/navlinks';

interface IProps {
  onExpandedChange: (value: boolean) => void;
}

export const PrimaryDropDownLinks = ({ onExpandedChange }: IProps): JSX.Element => {
  const pathname = usePathname();
  const { currentPage } = useAnalytics();

  return (
    <ul
      className="mx-6 mb-0 mt-4 border-b border-twilight sm:mx-11 sm:mt-6 md:hidden"
      data-testid="PrimaryDropDownLinks"
    >
      {MAIN_NAV_LINKS.map(({ path, title, type, id }: INavLinkItem) => {
        const isActiveTab = path === pathname;

        return (
          <li
            className="mb-4 sm:mb-6 md:mb-4"
            key={`dropdown-secondary-nav-link-${id}`}
            data-testid="PrimaryDropDownLinks item"
          >
            {type === 'external' ? (
              <ExternalLink
                href={path}
                variant="textNone"
                onClick={() => {
                  onExpandedChange(false);
                  trackNavigation({ path, currentPage });
                  void trackEvent({
                    name: 'mykiss_hamburger_clicked',
                    properties: { action: 'navigate', source: currentPage },
                  });
                }}
                data-testid={`dropdown-secondary-nav-link-${id}`}
              >
                <Heading
                  withDefaults={false}
                  variant="md"
                  color={isActiveTab ? 'navHighlight' : 'dark'}
                  className="flex items-start font-heading text-xl hover:text-trench"
                  as="span"
                >
                  <Image
                    alt="Active Link Icon"
                    width={6}
                    height={20}
                    className={`my-auto -ml-6 mr-[18px] sm:-ml-11 sm:mr-[38px] ${
                      isActiveTab ? 'block' : 'hidden'
                    }`}
                    src={activeLinkIcon}
                  />
                  {title}
                </Heading>
              </ExternalLink>
            ) : (
              <>
                <InternalLink
                  to={path}
                  variant="textNone"
                  onClick={() => {
                    onExpandedChange(false);
                    trackNavigation({ path, currentPage });
                    void trackEvent({
                      name: 'mykiss_hamburger_clicked',
                      properties: { action: 'navigate', source: currentPage },
                    });
                  }}
                  data-testid={`dropdown-secondary-nav-link-${id}`}
                >
                  <Heading
                    withDefaults={false}
                    variant="md"
                    color={isActiveTab ? 'navHighlight' : 'dark'}
                    className="flex items-start font-heading text-xl hover:text-trench"
                    as="span"
                  >
                    <Image
                      alt="Active Link Icon"
                      width={6}
                      height={20}
                      className={`my-auto -ml-6 mr-[18px] sm:-ml-11 sm:mr-[38px] ${
                        isActiveTab ? 'block' : 'hidden'
                      }`}
                      src={activeLinkIcon}
                    />
                    {title}
                  </Heading>
                </InternalLink>
              </>
            )}
          </li>
        );
      })}
    </ul>
  );
};
