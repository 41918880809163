import { EMPTY_LIST_SIX } from '../../../helpers/emptyListsForMapping';

export const EmptyResults = EMPTY_LIST_SIX.map(({ id }) => {
  return (
    <div className="mb-4 w-full flex-grow" key={id}>
      <div className="w-1/2 space-y-1 overflow-hidden">
        <div className="h-2 w-1/2 rounded-sm bg-twilight" />
        <div className="h-2 rounded-sm bg-twilight" />
      </div>
    </div>
  );
});
