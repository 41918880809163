import React from 'react';

import { useUserInfo } from '../../../helpers/hooks/useUserInfo';
import { EventMykissSearchResultSelected } from '../../../interfaces/events';
import { ISearchResult } from '../../../interfaces/search';
import { DEFAULT_RESULTS } from '../helpers/constants';

import { AnglerSignUpCTA } from './AnglerSignupCTA';
import { GroupedOptions } from './GroupedOptions';

interface IOptionsProps {
  results: ISearchResult;
  searchTerm: string;
  isSearchConfirmed: boolean;
  isAnglersFetching: boolean;
  isSpeciesFetching: boolean;
  isWatersFetching: boolean;
  onCloseSearchBox: () => void;
  source: EventMykissSearchResultSelected['properties']['source'];
}

export const SearchOptions = ({
  results = DEFAULT_RESULTS,
  searchTerm,
  isSearchConfirmed,
  isAnglersFetching,
  isSpeciesFetching,
  isWatersFetching,
  onCloseSearchBox,
  source,
}: IOptionsProps): JSX.Element => {
  const { isLoggedIn } = useUserInfo();

  return (
    <div data-testid="search-options" className="flex flex-col px-6 md:px-16 lg:px-16">
      {results && (
        <div className="flex flex-col items-start justify-between md:flex-row">
          {isLoggedIn ? (
            <GroupedOptions
              group={results.userList}
              searchTerm={searchTerm}
              onCloseSearchBox={onCloseSearchBox}
              isFetching={isAnglersFetching}
              isSearchConfirmed={isSearchConfirmed}
              source={source}
            />
          ) : (
            <AnglerSignUpCTA />
          )}

          <GroupedOptions
            group={results.waterList}
            searchTerm={searchTerm}
            onCloseSearchBox={onCloseSearchBox}
            isFetching={isWatersFetching}
            source={source}
          />
          <GroupedOptions
            group={results.speciesList}
            searchTerm={searchTerm}
            onCloseSearchBox={onCloseSearchBox}
            isFetching={isSpeciesFetching}
            source={source}
          />
        </div>
      )}
    </div>
  );
};
