import dynamic from 'next/dynamic';

import type { Context, Events, States } from '../machine/types';
import type { Flow } from '../types/flows/Flow';

const DynamicLogin = dynamic(async () => import('./login/Login').then(mod => mod.Login));
const DynamicSignUp = dynamic(async () => import('./signUp/SignUp').then(mod => mod.SignUp));
const DynamicLoading = dynamic(async () =>
  import('../components/Loading/Loading').then(mod => mod.Loading),
);
const DynamicWelcomeFreeUser = dynamic(async () =>
  import('./signUp/views/WelcomeFreeUser/WelcomeFreeUser').then(mod => mod.WelcomeFreeUser),
);

export const Flows: Record<States, Flow<Context, Events>> = {
  login: DynamicLogin,
  signUp: DynamicSignUp,
  loadingCurrentUser: <Flow<Context, Events>>DynamicLoading,
  checkIfCurrentUserIsNew: <Flow<Context, Events>>DynamicLoading,
  welcomeFreeUser: <Flow<Context, Events>>DynamicWelcomeFreeUser,
};
