export enum QueryStringKey {
  CognitoPasswordResetUsername = 'username',
  CognitoPasswordResetCode = 'code',
  FeatureFlags = 'flag',
  Lat = 'lat',
  Lng = 'lng',
  Symbol = 'symbol',
  GoogleClickIdentifier = 'gclid',
  FacebookClickIdentifier = 'fbclid',
  Campaign = 'campaign',
  WaterId = 'waterId',
  WaterName = 'waterName',
  AppShare = 'appshare',
  CatchViewerOpen = 'cv_open',
  CatchViewerCatchId = 'cv_id',
  CatchViewerEventSource = 'cv_src',
}
// Converts an object to a query string
export const objectToQueryString = (params: Record<string, any>): string => {
  return new URLSearchParams(params).toString();
};

// Converts a query string to an object
export const queryStringToObject = (queryString: string | undefined): Record<string, string> => {
  if (queryString === undefined) return {};
  return Object.fromEntries(new URLSearchParams(queryString)) as Record<string, string>;
};

// Combines a URL and query parameters into a full URL
export const createUrlWithQuery = ({
  url,
  query,
}: {
  url: string;
  query: Record<string, string>;
}): string => {
  return `${url}?${objectToQueryString(query)}`;
};
